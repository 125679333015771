import React from 'react';

import { ILocation } from '@wix/native-components-infra/dist/src/types/types';
import { ScreenNames } from '../main/biInterfaces';

export enum Pages {
  List = 'Challenges',
  Details = 'challenge_page',
  Payment = 'Challenge Payment',
  ThankYou = 'Thank You Page',
  ChallengesList = 'Challenges List Page',
}

export const ScreenNamesByPageId: Record<Pages, ScreenNames> = {
  [Pages.Details]: ScreenNames.ChallengePageForParticipant,
  [Pages.ChallengesList]: ScreenNames.ChallengesListPage,
  [Pages.List]: ScreenNames.ChallengeListWidget,
  [Pages.Payment]: ScreenNames.PaymentPage,
  [Pages.ThankYou]: ScreenNames.ThankYouPage,
};

export const PageSlugs: Record<Pages, string> = {
  [Pages.List]: 'challenges',
  [Pages.Details]: 'challenge-page',
  [Pages.Payment]: 'challenge-payment',
  [Pages.ThankYou]: 'challenge-thanks',
  [Pages.ChallengesList]: 'challenges-list',
};

export interface IGoToPageOptions {
  pageId: Pages | string;
  appDefinitionId?: string;
  challengeId?: string;
  reloadPage?: boolean;
  queryParams?: { [key: string]: string };
  isAbsoluteURL?: boolean;
}

export interface ILocationContext {
  location: ILocation;
  baseUrl: string;
  path: string[];
  query: {
    [key: string]: string;
  };
  goToExternalUrl(url: string): void;
  goToPage(options: IGoToPageOptions): void;
}

export const LocationContext = React.createContext<ILocationContext>({
  location: null,
  baseUrl: '',
  path: [],
  query: {},
  goToExternalUrl: () => {},
  goToPage: () => {},
});

export const useLocation = () => React.useContext(LocationContext);

export const LocationContextConsumer = LocationContext.Consumer;
