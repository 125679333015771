import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { HttpResponse } from '@wix/ricos-viewer';
import { HttpRequestOrHttpRequestFactory } from '@wix/fe-essentials/dist/types/exports/http-client';

export async function request<T>(
  flowAPI: Partial<ControllerFlowAPI>,
  options: HttpRequestOrHttpRequestFactory<T>,
  additionalHeaders?: Record<'Authorization', string>,
): Promise<HttpResponse<T>> {
  let response = null;

  response = await flowAPI.httpClient.request(function (context) {
    if (typeof options === 'function') {
      options = options(context);
    }
    return {
      ...options,
      ...{
        headers: {
          ...(options as any).headers,
          ...additionalHeaders,
          ...getMLHeaders(flowAPI),
        },
      },
    };
  });

  return response;
}

export const getMLHeaders = (flowAPI?: Partial<ControllerFlowAPI>) => {
  const headers = {};

  // We need to pass ML header to our BE if its enabled.
  if (flowAPI?.environment?.multilingual.isEnabled) {
    const currMLLanguage = flowAPI.environment.multilingual.siteLanguages.find(
      (lang) =>
        lang?.languageCode === flowAPI.environment.multilingual.currentLanguage,
    );

    if (currMLLanguage) {
      headers[
        'x-wix-linguist'
      ] = `${currMLLanguage?.languageCode}|${currMLLanguage?.locale}|${currMLLanguage?.isPrimaryLanguage}|${flowAPI.environment.appDefinitionId}`;
    }
  }

  return headers;
};
