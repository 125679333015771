import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { Challenge } from '@wix/ambassador-challenges-v1-challenge/types';
import format from 'date-fns/format';

const getDatesConfig = (
  challenge: Challenge,
): { lastMod: string; created: string } => {
  if (!challenge) {
    return null;
  }

  const { transitions } = challenge;
  const lastDate = format(new Date(transitions[0].occurredAt), 'd-MM-yyyy');

  if (transitions.length === 1) {
    return {
      lastMod: lastDate,
      created: lastDate,
    };
  }

  return {
    lastMod: lastDate,
    created: format(
      new Date(transitions[transitions.length - 1].occurredAt),
      'd-M-yyyy',
    ),
  };
};

const getPricingData = (
  challenge: Challenge,
): { currency: string; price: string } => {
  if (!challenge) {
    return null;
  }

  const currency = challenge.settings.pricing?.singlePayment?.price?.currency;

  return {
    ...(currency && { currency }),
    price: challenge.settings.pricing?.singlePayment?.price?.amount || '0',
  };
};

const getImageData = (
  challenge: Challenge,
): { mainImage: { id: string; height: string; width: string } } | null => {
  if (!challenge) {
    return null;
  }

  const { media } = challenge.settings.description;
  if (media?.image?.id) {
    return {
      mainImage: {
        id: media?.image?.id,
        width: '1000',
        height: '800',
      },
    };
  }

  return null;
};

const getAvailability = (challenge: Challenge): string => {
  if (!challenge) {
    return 'SECRET';
  }

  // for the secret challenge SEO team add title to page 'Access Forbidden'
  // no matter for Participants of a challenge o Visitor.
  // Bloody workaround.
  if (challenge.settings.accessRestrictions.accessType === 'SECRET') {
    return 'PRIVATE';
  }

  return challenge.settings.accessRestrictions.accessType;
};

export async function renderSeoMetatags(
  flowAPI: ControllerFlowAPI,
  challenge: Challenge,
) {
  const { isViewer } = flowAPI.environment;

  if (!isViewer) {
    return;
  }

  const { wixCodeApi } = flowAPI.controllerConfig;
  const seoAPI = wixCodeApi.seo;
  const { url, query } = wixCodeApi.location;
  const shareTitle = query.shareTitle;

  const name = challenge
    ? shareTitle
      ? decodeURIComponent(shareTitle)
      : challenge.settings?.description?.title
    : null;
  const description = challenge
    ? challenge.settings?.description?.details
    : null;

  const itemData = {
    challenge: {
      id: challenge?.id || null,
      ...(getDatesConfig(challenge) || {}),
      url,
      name: name || null,
      ...(description && {
        description,
      }),
      registration: {
        availability: getAvailability(challenge),
        ...(getPricingData(challenge) || {}),
      },
      ...(getImageData(challenge) || {}),
    },
    platformizedObject: challenge,
  };

  await seoAPI.renderSEOTags({
    itemType: 'CHALLENGES_PAGE',
    itemData,
    seoData: challenge?.settings?.seo?.seoData,
  });
}
